<template>
  <div :class="height" class="links">
    <div v-if="isSeeMore" class="see-more-container" @click="anchorTo(anchorId ?? '')"></div>
    <div class="socials">
      <div
        v-for="social in socials"
        :key="social.icon"
        class="social"
        @click="redirect(social.url)"
      >
        <Icon width="24px" height="24px" :name="social.icon" :fill="mainColor" />
      </div>
    </div>
    <div v-if="isSeeMore" class="see-more-container" @click="anchorTo(anchorId ?? '')">
      <div class="see-more">
        <Icon class="arrow" name="back-arrow" :fill="mainColor" /> Voir plus
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Social } from '@/lib/strapi-types/components/Social';

withDefaults(
  defineProps<{
    socials: Social[];
    mainColor?: string;
    height?: string; // TODO: Should we remove this?,
    anchorId?: string;
    isSeeMore?: boolean;
  }>(),
  { anchorId: undefined, mainColor: 'black', height: 'full' }
);

function redirect(link: string): void {
  window.open(link, '_blank');
}
function anchorTo(id: string): void {
  if (id.length > 0) window.location.hash = `#${id}`;
}
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/mouse-behavior.scss';

.links {
  // TODO: Rework that

  z-index: 1;
  flex-direction: column;
  flex-shrink: 0;
  place-content: space-between center;

  // We maybe could have use display: grid; instead for this layout like for the other similar ones
  .socials,
  .see-more-container {
    flex: 0.33;
    flex-direction: column;
  }

  .socials .social,
  .see-more-container .see-more {
    @include mouse-behavior.clickable-deep($color-base: v-bind(mainColor));
  }

  .socials {
    flex-direction: column;
    gap: 28px;
    align-items: center;
  }

  .see-more-container {
    justify-content: flex-end;

    .see-more {
      transform: rotate(-90deg) translateX(50%);

      gap: 8px;

      margin-left: -3px;

      font-size: 15px;
      font-weight: 600;
      color: v-bind(mainColor);

      .arrow {
        margin-top: 1px;
      }
    }
  }
}

.full {
  height: 100%;
}

.three-quarter {
  height: 75%;
}

.medium {
  height: 50%;
}
</style>
